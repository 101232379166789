import { ComponentPropsWithoutRef } from 'react';

import classNames from 'classnames';

import { MembershipTierId } from '@api';

import { membershipTiers } from '@shared/constants/membershipTiers';
import { useFeatureToggles } from '@shared/hooks/useFeatureToggles';

import { membershipBgClassName } from '../Avatar/Avatar';

export interface MembershipTierBannerProps extends ComponentPropsWithoutRef<'div'> {
  tier?: MembershipTierId;
}

const MembershipTierBanner = ({ tier, className, ...props }: MembershipTierBannerProps) => {
  const { membership } = useFeatureToggles();

  if (!membership || !tier) {
    return null;
  }

  const { icon: Icon } = membershipTiers[tier];

  return (
    <div
      className={classNames(
        membershipBgClassName[tier],
        'w-full h-notification-banner flex items-center px-[32px] text-neutral-50 gap-3 text-base leading-5 font-medium overflow-hidden',
        tier === 'FREE' ? 'text-neutral-500' : 'text-neutral-50',
        className,
      )}
      {...props}
      data-qa="membership-tier-banner"
    >
      <Icon className="icon w-[20px] h-[20px]" />
      {membershipTiers[tier].name}
    </div>
  );
};

export default MembershipTierBanner;
