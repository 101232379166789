'use client';

import { useTranslations } from 'next-intl';

import { ReactNode } from 'react';

import classNames from 'classnames';

import BaseInfoTooltip, { BaseInfoTooltipProps } from '@shared/common/components/BaseInfoTooltip';
import { useFeatureToggles } from '@shared/hooks/useFeatureToggles';

interface I18nInfoTooltipProps extends Omit<BaseInfoTooltipProps, 'content' | 'title'> {
  title?: string;
  content: string | ((params: { t: ReturnType<typeof useTranslations<string>> }) => ReactNode);
  namespace?: string;
}

const I18nInfoTooltip = ({
  title,
  content,
  iconClassName,
  namespace = 'tooltip',
  ...props
}: I18nInfoTooltipProps) => {
  const featureToggles = useFeatureToggles();
  const t = useTranslations(namespace);

  return (
    <BaseInfoTooltip
      title={title ? t.raw(title) : undefined}
      content={typeof content === 'function' ? content({ t }) : t.raw(content)}
      iconClassName={classNames(iconClassName, {
        'hidden lg:block': featureToggles.mobileHideTooltip,
      })}
      {...props}
    />
  );
};

export default I18nInfoTooltip;
